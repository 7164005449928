import React, { useRef } from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Item from "../components/item";
// import CollectionItem from "../components/collectionItem";

import {
  Link,
  Button,
  CheckPoint,
  DescHeading,
  Directiontext,
  Hero,
  IconBox,
  SectionHeader,
} from "../components/common";

import Subscription from "../components/subscription";
// import { useMediaQuery, VIEWPORT_SCREEN_SIZE } from "../helpers";
import CollectionItem from "../components/collectionItem";

import "./home.css";
import { getStaticSEOImage } from "../helpers";

function HomePage({
  pageContext: {
    data: products,
    abstractCategoryItems,
    businessCategoryItems,
    screenCategoryItems,
    freebies,
    collections,
  },
  location,
}) {
  const curatedCollectionSection = useRef(null);
  // const isMobile = useMediaQuery(VIEWPORT_SCREEN_SIZE.md);

  if (!products?.length || !collections?.length) {
    return null;
  }

  const handleScrollToSection = (e) => {
    e.preventDefault();

    const section = curatedCollectionSection?.current;

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Home"
        description="Show Off Your Content with Stunning Background Images"
        image={getStaticSEOImage("home")}
        path={location?.pathname}
      />
      <section className="mt-12 lg:mt-24 md:mt-16 mb-24 lg:mb-24 md:mb-16 flex justify-center">
        <Hero
          subTitle="Pro Visual Graphics"
          title={
            <>
              Show Off Your Content
              <br />
              With Stunning Background Images
            </>
          }
          description="Beautify your content, design, presentation, website with carefully crafted background images. Steal the spotlight of your potential customers and convert them."
          action={
            <div className="mt-6 md:mt-8 relative inline-block w-full md:w-auto">
              <Button
                onClick={handleScrollToSection}
                hasMoustache
                text="Shop Now"
                icon="vg-arrow-right"
              />
            </div>
          }
        >
          <div className="flex lg:flex-row flex-col items-center gap-x-12 gap-y-2">
            <CheckPoint content="Buy Once & Use Unlimited" />
            <CheckPoint content="Do Whatever With One License" />
            <CheckPoint content="60-Day Refund Guarantee" />
          </div>
        </Hero>
      </section>
      <section
        className="dev-vg-g-product-section mb-24 md:mb-40"
        ref={curatedCollectionSection}
      >
        <div className="dev-vg-g-product-section__head mx-auto flex items-end md:justify-between justify-center mb-12 md:mb-8 text-ui-gray-plus-4">
          <DescHeading
            title="Curated Collections"
            subtitle="Create your big thing with thematic collections"
          />
          <Directiontext
            noMobile
            noTablet
            text="Explore More"
            to="/collections"
          />
        </div>
        <div className="dev-vg-g-product-section__items mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 lg:gap-y-16 gap-y-12">
          {collections.map((collection, index) => (
            <CollectionItem collection={collection} key={index} />
          ))}
        </div>
        <div className="dev-vg-g-product-section__button mt-12 md:mt-16 flex items-center justify-center">
          <Button
            to="/collections"
            text="Explore Collections"
            icon="vg-arrow-right"
            hasMoreWidth
          />
        </div>
      </section>
      <section className="dev-vg-g-product-section dev-vg-home__category md:mb-40 mb-24">
        <div className="dev-vg-g-product-section__head mx-auto flex items-end justify-center mb-12 md:mb-8 text-ui-gray-plus-4 md:justify-between">
          <DescHeading
            title="Latest Listings"
            subtitle="Make your project posh with newly made photos"
          />
          <Directiontext
            noMobile
            text="Explore Latest Backgrounds"
            to="/products"
          />
        </div>
        <div className="dev-vg-g-product-section__items mx-auto grid grid-cols-2 lg:grid-cols-3 md:gap-x-8 gap-x-6 gap-y-8 md:gap-y-16">
          {products.map((product) => (
            <Item
              key={product.id}
              title={product.Name}
              price={product.Price}
              salePrice={product.SalePrice}
              featuredImage={product.FeaturedImage.data.attributes.localFile}
              url={product.URL}
            />
          ))}
        </div>
        <div className="dev-vg-g-product-section__button md:mt-16 mt-12 flex items-center justify-center dev-vg-home__category__button">
          <Button
            to="/products"
            text="Explore Latest Products"
            contentInOneLine
            hasMoreWidth
            icon="vg-arrow-right"
          />
        </div>
      </section>
      {abstractCategoryItems?.[0].products?.length && (
        <section className="mb-24 lg:mb-40 dev-vg-home__category">
          <div className="dev-vg-g-product-section__head flex items-end md:justify-between justify-center mb-8 text-ui-gray-plus-4">
            <DescHeading
              title="Abstract Artworks"
              subtitle="Style your surrounding to feel fresh everyday"
            />
            <Directiontext
              noMobile
              text="Explore Abstract Backgrounds"
              to="/category/abstract"
            />
          </div>
          <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
            {abstractCategoryItems[0]?.products?.map((product) => (
              <Item
                key={product.id}
                title={product.Name}
                price={product.Price}
                salePrice={product.SalePrice}
                featuredImage={product.FeaturedImage.data.attributes.localFile}
                url={product.URL}
                fromCategory={{
                  Name: "Abstract",
                  URL: "abstract",
                }}
              />
            ))}
          </div>
          <div className="dev-vg-g-product-section__button dev-vg-home__category__button md:mt-16 mt-12 flex items-center justify-center">
            <Button
              to="/category/abstract"
              text="Explore Abstract Backgrounds"
              tabletText="Explore Abstract"
              icon="vg-arrow-right"
              hasMoreWidth
            />
          </div>
        </section>
      )}
      {businessCategoryItems?.[0].products?.length && (
        <section className="mb-24 lg:mb-40 dev-vg-home__category">
          <div className="dev-vg-g-product-section__head flex items-center md:justify-between justify-center mb-8 text-ui-gray-plus-4">
            <DescHeading
              title="Business Backgrounds"
              subtitle="Grow your business with scroll stopping graphics"
            />
            <Directiontext
              noMobile
              text="Explore Business Backgrounds"
              to="/category/business"
            />
          </div>
          <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
            {businessCategoryItems[0]?.products?.map((product) => (
              <Item
                key={product.id}
                title={product.Name}
                price={product.Price}
                salePrice={product.SalePrice}
                featuredImage={product.FeaturedImage.data.attributes.localFile}
                url={product.URL}
                fromCategory={{
                  Name: "Business",
                  URL: "business",
                }}
              />
            ))}
          </div>
          <div className="dev-vg-g-product-section__button md:mt-16 mt-12 flex items-center justify-center dev-vg-home__category__button">
            <Button
              to="/category/business"
              text="Explore Business Backgrounds"
              tabletText="Explore Business"
              icon="vg-arrow-right"
              hasMoreWidth
              contentInOneLine
            />
          </div>
        </section>
      )}
      {screenCategoryItems?.[0].products?.length && (
        <section className="mb-24 lg:mb-32 dev-vg-home__category">
          <div className="dev-vg-g-product-section__head flex items-end md:justify-between justify-center mb-8 text-ui-gray-plus-4">
            <DescHeading
              title="Screen Stylers"
              subtitle="Decor your smart devices to delight yourself"
            />
            <Directiontext
              noMobile
              text="Explore Screen Backgrounds"
              to="/category/screen"
            />
          </div>
          <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
            {screenCategoryItems[0]?.products?.map((product) => (
              <Item
                key={product.id}
                title={product.Name}
                price={product.Price}
                salePrice={product.SalePrice}
                featuredImage={product.FeaturedImage.data.attributes.localFile}
                url={product.URL}
                fromCategory={{
                  Name: "Screen",
                  URL: "screen",
                }}
              />
            ))}
          </div>
          <div className="dev-vg-g-product-section__button md:mt-16 mt-12 flex items-center justify-center dev-vg-home__category__button">
            <Button
              to="/category/screen"
              text="Explore Screen Backgrounds"
              tabletText="Explore Screen"
              icon="vg-arrow-right"
            />
          </div>
        </section>
      )}
      <section className="md:mb-40 mb-24">
        <div className="mb-16">
          <SectionHeader
            art
            title="Jaw Dropping Perks"
            description="We aim to give more, to ensure you grow big. Get the best perks for your best money. Use our products unlimited times in unlimited projects by unlimited ways."
          />
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:gap-8 gap-12">
          <IconBox title="Unlimited Use" icon="vg-infinity">
            Buy once, keep forever and use in unlimited projects.
          </IconBox>
          <IconBox title="Do Whatever" icon="vg-file">
            One{" "}
            <Link className="text-brand-primary underline" to="/license">
              simple license
            </Link>{" "}
            to use in any projects, any quantity.
          </IconBox>
          <IconBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            title="60-Day Refund"
            icon="vg-square"
          >
            Checkout with confidence. And get a piece of mind, for{" "}
            <Link className="text-brand-primary underline" to="/refund">
              60 days!
            </Link>
          </IconBox>
        </div>
      </section>
      {freebies?.[0].products?.length && (
        <section className="lg:mb-56 mb-40 dev-vg-home__category">
          <div className="dev-vg-g-product-section__head flex items-end md:justify-between justify-center lg:mb-8 mb-12 text-ui-gray-plus-4">
            <DescHeading
              title="Fabulous Freebies"
              subtitle="Start your side hustle without a spending"
            />
            <Directiontext
              noMobile
              text="Explore Background Freebies"
              to="/category/freebies"
            />
          </div>
          <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
            {freebies[0]?.products?.map((product) => (
              <Item
                key={product.id}
                title={product.Name}
                price={product.Price}
                salePrice={product.SalePrice}
                featuredImage={product.FeaturedImage.data.attributes.localFile}
                url={product.URL}
                fromCategory={{
                  Name: "Freebies",
                  URL: "freebies",
                }}
              />
            ))}
          </div>
          <div className="dev-vg-g-product-section__button dev-vg-home__category__button md:mt-16 mt-12 flex items-center justify-center">
            <Button
              to="/category/freebies"
              text="Explore Background Freebies"
              tabletText="Explore Freebies"
              icon="vg-arrow-right"
              hasMoreWidth
            />
          </div>
        </section>
      )}
      <section className="lg:pb-16 pb-8">
        <Subscription />
      </section>
    </Layout>
  );
}

HomePage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default HomePage;
