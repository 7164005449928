import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./collectionItem.css";
import { generateAlt, generateHeading } from "../helpers";

const CollectionItem = ({ collection }) => {
  const headline = generateHeading(
    collection.Headline,
    collection.productsCount
  );
  return (
    <Link
      className="dev-vg-collection-item dev-vg-g-focus"
      to={`/collection/${collection.URL}`}
    >
      <div className="dev-vg-collection-item__grid flex transform hover:-translate-y-2 transition-all duration-200">
        <div className="dev-vg-collection-item__image-container col-span-2">
          <GatsbyImage
            className="object-cover w-full rounded-l-xl"
            alt={generateAlt("collection", headline)}
            image={getImage(
              collection.products[0]?.FeaturedImage?.data?.attributes?.localFile
            )}
          />
        </div>
        <div className="dev-vg-collection-item__image-container grid grid-cols-1">
          <GatsbyImage
            className="dev-vg-collection-item__image object-cover w-full rounded-tr-xl"
            alt={generateAlt(
              "collection",
              collection.Headline,
              collection.productsCount
            )}
            image={getImage(
              collection.products[1]?.FeaturedImage?.data?.attributes?.localFile
            )}
            loading="lazy"
          />
          <GatsbyImage
            className="dev-vg-collection-item__image object-cover w-full"
            alt={generateAlt(
              "collection",
              collection.Headline,
              collection.productsCount
            )}
            image={getImage(
              collection.products[2]?.FeaturedImage?.data?.attributes?.localFile
            )}
          />
          <GatsbyImage
            className="dev-vg-collection-item__image object-cover w-full rounded-br-xl"
            alt={generateAlt(
              "collection",
              collection.Headline,
              collection.productsCount
            )}
            image={getImage(
              collection.products[3]?.FeaturedImage?.data?.attributes?.localFile
            )}
          />
        </div>
      </div>
      <div className="dev-vg-collection-item__content mt-4">
        <h5 className="text-pre-lg font-bold leading-7 tracking-tightsy text-ui-gray-plus-4">
          {headline}
        </h5>
      </div>
    </Link>
  );
};

CollectionItem.propTypes = {
  collection: PropTypes.object,
};

CollectionItem.defaultProps = {
  collection: {
    Name: "Surface Pattern",
    Description: "Simple Discription",
    URL: "collection",
  },
};

export default CollectionItem;
