import React from "react";
import { Link, navigate } from "gatsby";
import classnames from "classnames";
import PropTyeps from "prop-types";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./item.css";
import { createRipple } from "../helpers";

const Item = ({
  title,
  price,
  salePrice,
  featuredImage,
  url,
  fromCategory,
  minVersion,
  hidePricing,
}) => {
  return (
    <Link
      className="dev-vg-item focus:outline-none dev-vg-g-focus"
      onClick={(e) => {
        e.preventDefault();

        setTimeout(() => {
          navigate(`/product/${url}`);
        });
      }}
      to={`/product/${url}`}
      state={fromCategory}
    >
      <div
        onClick={createRipple}
        className="transform hover:-translate-y-2 transition-all duration-200 group overflow-hidden dev-vg-g-focus"
      >
        <GatsbyImage
          className="object-cover relative rounded-xl opacity-100 group-hover:opacity-90 duration-200"
          image={getImage(featuredImage)}
        />
        {!!salePrice && (
          <span
            className={classnames(
              "absolute bg-semantic-alert-minus-1 z-10 text-semantic-alert-plus-1 py-1 px-2 rounded-sm font-bold text-xs hidden md:inline-block",
              { "left-6 top-6": !minVersion, "left-4 top-4": minVersion }
            )}
          >
            SALE
            {minVersion ? (
              ""
            ) : (
              <span className="hidden lg:inline-block ml-1">{`- ${(
                100 -
                (salePrice / price) * 100
              ).toFixed(1)}% OFF`}</span>
            )}
          </span>
        )}
      </div>
      <div className="dev-vg-item__content flex items-center justify-between mt-4">
        <h5 className="dev-vg-item__content__title overflow-ellipsis overflow-hidden lg:mr-7 mr-0 whitespace-nowrap">
          {title}
        </h5>
        {!hidePricing && (
          <h5 className="md:block hidden">
            {price ? (
              <>
                <span
                  className={classnames("mr-2", {
                    "text-content-mild line-through": salePrice,
                  })}
                >
                  ${price}
                </span>
                {!!salePrice && <>${salePrice}</>}
              </>
            ) : (
              "Free"
            )}
          </h5>
        )}
      </div>
    </Link>
  );
};

Item.defaultProps = {
  title: "Abstract Glossy 3D Renders",
};

Item.propTypes = {
  title: PropTyeps.string,
  price: PropTyeps.number,
  salePrice: PropTyeps.number,
  featuredImage: PropTyeps.string,
  url: PropTyeps.string,
  fromCategory: PropTyeps.object,
  minVersion: PropTyeps.bool,
  hidePricing: PropTyeps.bool,
};

export default Item;
