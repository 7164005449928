import React, { useState } from "react";
import axios from "axios";
import classnames from "classnames";
import { navigate } from "gatsby";

import { AlertModal, Button, CheckPoint, Link, Input } from "./common";

import VeticleMoustache from "../assets/images/vectors/verticle-moustache.svg";
import Leaf from "../assets/images/vectors/leaf.svg";
import { ALERT_INITIAL_STATE, apiURL } from "../helpers";

import "./subscription.css";

const Subscription = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    loading: false,
    alert: ALERT_INITIAL_STATE,
    validation: { name: true, email: true },
  });
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (isSubmissionAllowed() && !state.loading) {
      setState({ ...state, loading: true });
      const { name, email } = state;
      const { data } = await axios.post(apiURL + "/email-subscribers", {
        name,
        email,
      });

      switch (data.code) {
        case "subscriber_request_email_sent":
          setState({ ...state, loading: false });
          return navigate("/confirm-to-download", {
            state: {
              email,
            },
          });
        case "subscriber_already_exist":
          return setState({
            ...state,
            loading: false,
            alert: {
              success: true,
              error: false,
              title: "You're already in!",
              content:
                "Thanks for being with us! It looks like you've already subscribed to our list",
            },
          });
      }
    }
  };
  const onValidate = (name, status) => {
    setState({
      ...state,
      validation: {
        ...state.validation,
        [name]: status,
      },
    });
  };
  const isSubmissionAllowed = () =>
    state.email &&
    state.name &&
    state.validation.name &&
    state.validation.email;

  // const isAnyFieldHasError = () =>
  //   !state.validation.name || !state.validation.email;

  return (
    <>
      <AlertModal
        show={state.alert.error || state.alert.success}
        type={state.alert?.error ? "error" : "success"}
        title={state?.alert?.title}
        content={state?.alert?.content}
        onClose={() => setState({ ...state, alert: ALERT_INITIAL_STATE })}
      />
      <div className="dev-vg-subscription relative bg-background-white md:px-8 md:py-16 lg:px-20 px-6 lg:py-28 py-16 md:rounded-lg rounded-none flex flex-col items-center md:-mx-0 -mx-6">
        <div className="text-center lg:mb-6 mb-8">
          <p className="font-stylish text-brand-primary text-lg">Subscribe</p>
          <h2 className="pt-2 lg:pb-4 pb-6 md:text-3.5xl lg:text-4xl text-2xl lg:leading-14 md:leading-11 leading-9 lg:tracking-tightty tracking-tightie">
            <span className="hidden lg:inline-block">
              Get An Expert List of 50+ Finest Fonts
              <br />
              To Create Dazzling Designs
            </span>
            <span className="inline-block lg:hidden">
              Get An Expert List of 50+ Finest Fonts to Create Dazzling Designs
            </span>
          </h2>
          <div className="flex items-center justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-12 lg:gap-y-4 gap-y-2">
              <CheckPoint content="No Google Fonts" />
              <CheckPoint content="Free Commercial Use" />
              <CheckPoint className="flex-1" content="And Weekly Resources" />
            </div>
          </div>
        </div>
        <form
          className={classnames(
            "dev-vg-subscription__form grid grid-cols-6 items-center w-full gap-x-4 gap-y-4"
          )}
        >
          <div className="dev-vg-subscription__form-field col-span-6 md:col-span-3 lg:col-span-2 w-full md:w-auto">
            <Input
              name="name"
              className="py-5 px-6 border border-brand-primary rounded-lg w-full h-full"
              minLength={3}
              maxLength={32}
              onChange={(name) => setState({ ...state, name })}
              minLengthErrorMessage="Too short, write full name"
              value={state.name}
              placeholder="Your Name"
              onValidate={onValidate}
            />
          </div>
          <div className="dev-vg-subscription__form-field col-span-6 md:col-span-3 lg:col-span-2 w-full md:w-auto">
            <Input
              name="email"
              className="py-5 px-6 border border-brand-primary rounded-lg w-full h-full"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              onChange={(email) => setState({ ...state, email })}
              patternErrorMessage="Enter a valid email or try another"
              value={state.email}
              placeholder="email@example.com"
              onValidate={onValidate}
            />
          </div>
          <div className="dev-vg-subscription__form-field col-span-6 lg:col-span-2 lg:inline-block block w-full lg:w-auto relative">
            <div className="text-center md:text-left md:hidden">
              Your email is safe with our{" "}
              <Link to="/privacy">privacy policy</Link>. We send sweet emails
              weekly, unsubscribe anytime.
            </div>
            <Button
              isLoading={state.loading}
              onClick={handleSubmit}
              hasMinWidth={false}
              text="Subscribe Now"
              icon="vg-mail"
              disabled={!isSubmissionAllowed()}
            />
            <span
              style={{
                top: "-50px",
                right: "-45px",
              }}
              className="absolute lg:block hidden"
            >
              <img src={VeticleMoustache} alt="verticle-moustache-vector" />
            </span>
          </div>
        </form>
        <div className={classnames("text-tiny pt-4 hidden md:block")}>
          <div className="text-center md:text-left">
            Your email is safe with our{" "}
            <Link to="/privacy">privacy policy</Link>. We send sweet emails
            weekly, unsubscribe anytime.
          </div>
        </div>
        <span
          style={{
            top: "-64px",
            right: "calc(50% - 44px)",
          }}
          className="absolute"
        >
          <img src={Leaf} alt="leaf-vector" />
        </span>
      </div>
    </>
  );
};

export default Subscription;
